/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "fontsource-montserrat"
import "fontsource-montserrat/600.css"
import "fontsource-montserrat/700.css"
import "fontsource-montserrat/900.css"
import "fontsource-bebas-neue"
import "./src/css/index.css"
